<template>
    <div id="login">
        <div class="header-icon">
            <!--<img src="../assets/images/yike_logo.png" alt="">-->
        </div>
        <div class="login-content">
            <div class="content-left">
                <img src="../assets/images/login_bg_2.png" alt="">
            </div>
            <div class="login-form">
                <div class="login-content-title">{{webName}}</div>
                <el-form :model="ruleForm" :rules="rules" labelPosition="top" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入正确的用户名" @keyup.enter.native="submitForm('ruleForm')">
                            <i slot="prefix" class="iconfont">&#xe66d;</i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="ruleForm.password" type="password" placeholder="请输入正确的密码" show-password @keyup.enter.native="submitForm('ruleForm')">
                            <i slot="prefix" class="iconfont">&#xe60e;</i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="rem">
                        <el-checkbox v-model="ruleForm.rem" class="remember-pwd">记住密码</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                webName: '',
                siteName: '电子技能竞赛平台',
                ruleForm: {
                    name: '',
                    password: '',
                    rem: false
                },
                rules: {
                    name: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        created() {
            if (localStorage.getItem('username')) {
                if (Number(localStorage.getItem('role')) === 0) {
                    this.$router.push('/admin/index');
                } else if (Number(localStorage.getItem('role')) === 1) {
                    this.$router.push('/schooladmin/index');
                } else if (Number(localStorage.getItem('role')) === 2) {
                    this.$router.push('/trainadmin/index');
                } else if (Number(localStorage.getItem('role')) === 3) {
                    this.$router.push('/teacher/index');
                } else if (Number(localStorage.getItem('role')) === 4) {
                    this.$router.push('/student/index');
                }
            }
            this.$http.asyncGet(this.$api.web).then(res => {
                if (res.code === 200) {
                    this.webName = res.data.name;
                    localStorage.setItem('web', this.webName);
                } else {
                    this.webName = this.siteName;
                }
            }).catch(err => {
                console.log(err);
                this.webName = this.siteName;
            })
        },
        mounted() {
            this.getCookie();
        },
        methods: {
            submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const self = this;
                        //判断复选框是否被勾选 勾选则调用配置cookie方法
                        if (self.ruleForm.rem === true) {
                            //传入账号名，密码，和保存天数3个参数
                            self.setCookie(self.ruleForm.name, self.ruleForm.password, self.ruleForm.rem, 30);
                        } else {
                            //清空Cookie
                            self.clearCookie();
                        }
                        // 登录之前先清除所有的缓存信息
                        localStorage.clear();
                        let formData = new FormData();
                        formData.append('username', this.ruleForm.name);
                        formData.append('password', this.ruleForm.password);
                        this.$http.axiosPost(this.$api.login, formData, (res) => {
                            if (res.code === 200) {
                                if (Number(res.data.role) === 0) {
                                    //总管理员/超级管理员
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.name);
                                    localStorage.setItem('headerImg', res.data.headimgurl);
                                    localStorage.setItem('role', res.data.role);
                                    this.$lockr.set('competition_type', res.data.type);
                                    this.$message({
                                        message: res.msg,
                                        duration: 500,
                                        type: 'success',
                                        onClose: function() {
                                            _this.$router.push('/admin/index');
                                        }
                                    });
                                } else if (Number(res.data.role) === 1) {
                                    //学校管理员
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.name);
                                    localStorage.setItem('headerImg', res.data.headimgurl);
                                    localStorage.setItem('role', res.data.role);
                                    this.$message({
                                        message: res.msg,
                                        duration: 500,
                                        type: 'success',
                                        onClose: function() {
                                            _this.$router.push('/schooladmin/index');
                                        }
                                    });
                                } else if (Number(res.data.role) === 2) {
                                    //训练管理员
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.name);
                                    localStorage.setItem('headerImg', res.data.headimgurl);
                                    localStorage.setItem('role', res.data.role);
                                    this.$message({
                                        message: res.msg,
                                        duration: 500,
                                        type: 'success',
                                        onClose: function() {
                                            _this.$router.push('/trainadmin/index');
                                        }
                                    });
                                } else if (Number(res.data.role) === 3) {
                                    //评委
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.name);
                                    localStorage.setItem('headerImg', res.data.headimgurl);
                                    localStorage.setItem('role', res.data.role);
                                    this.$message({
                                        message: res.msg,
                                        duration: 500,
                                        type: 'success',
                                        onClose: function() {
                                            _this.$router.push('/teacher/index');
                                        }
                                    });
                                } else if (Number(res.data.role) === 4) {
                                    //学生
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.name);
                                    localStorage.setItem('headerImg', res.data.headimgurl);
                                    localStorage.setItem('role', res.data.role);
                                    localStorage.setItem('studentID', res.data.user_id);
                                    localStorage.setItem("type", res.data.type);
                                    this.$message({
                                        message: res.msg,
                                        duration: 500,
                                        type: 'success',
                                        onClose: function() {
                                            _this.$router.push('/student/index');
                                        }
                                    });
                                }
                            } else {
                                this.$message({
                                    message: res.msg,
                                    duration: 1000,
                                    type: 'error'
                                });
                            }
                        }, (err) => {
                            console.log(err);
                        })
                    } else {
                        return false;
                    }
                });
            },
            //设置cookie
            setCookie(c_name, c_pwd,c_status, exdays) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "userPwdStatus" + "=" + c_status + ";path=/;expires=" + exdate.toGMTString();
            },
            //读取cookie
            getCookie() {
                if (document.cookie.length > 0) {
                    let arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                    for (let i = 0; i < arr.length; i++) {
                        let arr2 = arr[i].split('='); //再次切割
                        //判断查找相对应的值
                        if (arr2[0] === 'userName') {
                            this.ruleForm.name = arr2[1]; //保存到保存数据的地方
                        } else if (arr2[0] === 'userPwd') {
                            this.ruleForm.password = arr2[1];
                        } else if (arr2[0] === 'userPwdStatus') {
                            if(arr2[1] === 'true'){
                                this.ruleForm.rem = true;
                            }else {
                                this.ruleForm.rem = false;
                            }
                        }
                    }
                }
            },
            //清除cookie
            clearCookie() {
                this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
            }
        }
    }
</script>

<style scoped lang="scss">
    #login {
        background: url(../assets/images/login_bg_1.png) no-repeat 50%;
        background-size: cover;
        width: 100%;
        height: 100vh;
        overflow: auto;
        position: relative;
        min-height: 600px;
        .header-icon {
            position: absolute;
            left: 20px;
            top: 20px;
        }
        .login-content {
            width: 1000px;
            min-width: 1000px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            align-items: center;
            overflow: hidden;
            box-shadow: 0 0 16px 0 rgba(62,58,234,0.1);
            .content-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .login-form {
                width: 50%;
                height: 100%;
                padding: 40px;
                box-sizing: border-box;
                .login-content-title {
                    font-size:30px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(17,34,216,1);
                    margin-bottom: 50px;
                }
                .el-button--primary {
                    width: 100%;
                    background-color: #1122D8;
                    border-color: #1122D8;
                    &:focus, &:hover {
                        background-color: #2838dc;
                        border-color: #2838dc;
                    }
                }
                .el-form-item {
                    margin-bottom: 30px;
                }
                .remember-pwd {
                    color: #343441;
                    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
                        color: #1122D8;
                    }
                    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #1122D8;
                        border-color: #1122D8;
                    }
                }
            }
        }
    }
</style>